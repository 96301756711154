import React, { useState } from 'react'
import { useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import FormGroup from '@mui/material/FormGroup'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Checkbox from '@mui/material/Checkbox'
import TimePicker from '@mui/lab/TimePicker'
import Typography from '@mui/material/Typography'

import DateRangePicker from 'components/common/DateRangePicker'
import Autocomplete from 'components/common/Autocomplete'
import FileDropzone from './FileDropzone'
import PrintAssetDropdown from './PrintAssetDropdown'
import AddressAutocomplete from 'components/common/AddressAutocomplete'
import capitalize from 'helpers/node/capitalize'
import NestedMultiselectField from './NestedMultiselectField'
import TagsField from './TagsField'
import FieldSeperator from './FieldSeperator'
import { COLLATERAL_FIELD_TYPES } from 'utils/constants'

const StyledCheckbox = styled(Checkbox)`
  &:hover {
    background-color: transparent !important;
  }
`

function Field({
  fieldData,
  onBlur,
  onFocus,
  loading,
  onChange,
  scrollRef,
  labelMaxLength,
}) {
  const { values, handleChange, errors, touched } = useFormikContext()
  const handleUpdateChange = ({ name, value, disableTimer }) => {
    handleChange({ target: { name, value } })
    onChange({ target: { name, value } })

    if (!disableTimer) {
      onBlur(
        {
          ...values,
          [name]: value,
        },
        fieldData
      )
    }
  }

  const handleTextFieldChange = (e) => {
    handleChange(e)
    onChange(e)
  }

  let {
    field,
    display,
    data_type: dataType,
    options,
    inputProps,
    placeholder,
    default_value: defaultValue,
    required,
    depends_on,
    maxLength,
  } = fieldData

  const isLabelOverflow =
    typeof display === 'string' &&
    labelMaxLength &&
    display.length > labelMaxLength

  const resolveShowIf = () => {
    const dependsOn = depends_on || {}

    const show = Object.entries(dependsOn).reduce((acc, [field, value]) => {
      return acc && values[field] === value
    }, true)

    return show
  }

  const [selectedOption, setSelectedOption] = useState()

  if (dataType === 'hidden' || !resolveShowIf()) {
    return <></>
  }

  if (dataType === 'divider') {
    return <FieldSeperator display={display} />
  }

  if (dataType === 'date' || dataType === 'datetime') {
    return (
      <>
        {isLabelOverflow && (
          <Typography color="darkgray.light" fontSize="12px">
            {display}
          </Typography>
        )}
        <DateRangePicker
          {...inputProps}
          id={field}
          name={field}
          label={isLabelOverflow ? undefined : display}
          value={values[field] || [undefined, undefined]}
          setValue={(value) => handleUpdateChange({ name: field, value })}
          modalProps={{ months: 2 }}
          required={required}
          disabled={loading || inputProps?.readOnly}
          error={Boolean(errors[field]) && touched[field]}
          helperText={
            Boolean(errors[field]) && touched[field]
              ? errors[field]
              : inputProps?.helper
          }
        />
      </>
    )
  }

  if (dataType === 'time') {
    return (
      <TimePicker
        value={values[field]}
        onChange={(value) => handleUpdateChange({ name: field, value: value })}
        renderInput={(props) => (
          <TextField
            fullWidth
            {...inputProps}
            {...props}
            id={field}
            name={field}
            label={display}
            error={Boolean(errors[field]) && touched[field]}
            helperText={
              Boolean(errors[field]) && touched[field]
                ? errors[field]
                : inputProps?.helper
            }
            disabled={loading || inputProps?.readOnly}
          />
        )}
      />
    )
  }

  if (dataType === 'tags') {
    return <TagsField field={field} display={display} />
  }

  if (dataType === 'address') {
    return (
      <AddressAutocomplete
        addressKeys={fieldData.addressKeys}
        textFieldProps={{ color: 'primary' }}
      />
    )
  }

  if (dataType === 'text' && options) {
    return (
      <Autocomplete
        searchValue={values[field] || ''}
        setSearchValue={(value) => handleUpdateChange({ name: field, value })}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        options={options}
        placeholder={display}
        handleFilter={undefined}
        textFieldProps={{ color: 'primary', ...inputProps }}
      />
    )
  }

  if (dataType === 'checkbox') {
    const checked =
      (typeof values[field] === 'string'
        ? values[field] === '1'
        : values[field]) || false

    return (
      <Box>
        <FormGroup row>
          <FormControlLabel
            control={
              <StyledCheckbox
                name={field}
                checked={checked}
                onChange={(e) =>
                  handleUpdateChange({
                    name: field,
                    value: e.target.checked,
                  })
                }
                disabled={loading}
              />
            }
            label={display}
          />
        </FormGroup>
      </Box>
    )
  }

  if (dataType === 'radio') {
    const options = fieldData.options || {
      [defaultValue]: defaultValue,
    }

    return (
      <FormControl component="fieldset" required={required}>
        <FormLabel component="legend">{display}</FormLabel>
        <RadioGroup
          row
          aria-label={field}
          name={field}
          value={values[field] || options[defaultValue]}
          onChange={(e) =>
            handleUpdateChange({ name: field, value: e.target.value })
          }
        >
          {Object.keys(options).map((option) => (
            <FormControlLabel
              key={option}
              value={option}
              control={<Radio />}
              label={capitalize(options[option])}
              disabled={loading}
            />
          ))}
        </RadioGroup>
      </FormControl>
    )
  }

  if (dataType === 'fileupload') {
    return (
      <FileDropzone
        file={values[field] || null}
        onChange={(file) =>
          handleUpdateChange({
            name: field,
            value: file,
            disableTimer: !Boolean(file),
          })
        }
        disabled={loading}
        fieldData={fieldData}
        error={errors[field]}
        onFocus={inputProps?.isCollateral ? () => onChange() : undefined}
        onFileDeattach={
          inputProps?.isCollateral
            ? () =>
                onChange({
                  force: true,
                  userValues: {
                    ...values,
                    [field]: undefined,
                  },
                })
            : undefined
        }
        {...inputProps}
      />
    )
  }

  if (dataType === 'printasset') {
    return (
      <PrintAssetDropdown
        fieldData={fieldData}
        disabled={loading}
        handleUpdateChange={handleUpdateChange}
      />
    )
  }

  if (dataType === 'nested_multiselect') {
    return (
      <NestedMultiselectField
        name={display}
        options={Object.values(options || {})}
        onChange={(selected) =>
          handleUpdateChange({ name: field, value: selected })
        }
        values={values[field] || []}
        error={errors[field]}
        scrollRef={scrollRef}
      />
    )
  }

  let selectProps = {
    MenuProps: { PaperProps: { sx: { maxHeight: '30% !important' } } },
  }

  if (dataType === COLLATERAL_FIELD_TYPES.MULTISELECT) {
    selectProps = {
      ...selectProps,
      multiple: true,
    }
  } else if (dataType === COLLATERAL_FIELD_TYPES.MATERIAL_SELECT_7102) {
    options =
      options?.[values.plan_year]?.[values.language]?.[values.lob || 'all'] ||
      {}

    selectProps = {
      ...selectProps,
      multiple: true,
      required: true,
    }
  } else if (dataType === COLLATERAL_FIELD_TYPES.KIT_SELECT_7102) {
    options =
      options?.[values.plan_year]?.[values.language]?.[values.lob || 'all'] ||
      {}
    selectProps = {
      ...selectProps,
      required: true,
    }
  }

  return (
    <>
      {isLabelOverflow && (
        <Typography color="darkgray.light" fontSize="12px">
          {display}
        </Typography>
      )}
      <TextField
        multiline={dataType === 'textarea'}
        rows={dataType === 'textarea' ? 3 : undefined}
        onBlur={() => onBlur(values, fieldData)}
        onFocus={() => onFocus(fieldData)}
        required={required}
        {...inputProps}
        id={field}
        name={field}
        label={isLabelOverflow ? undefined : display}
        placeholder={placeholder || display}
        value={values[field] || (Boolean(options) ? [] : '')}
        onChange={handleTextFieldChange}
        select={Boolean(options)}
        fullWidth
        InputLabelProps={{ shrink: values[field] ? true : undefined }}
        SelectProps={selectProps}
        inputProps={{
          min: dataType === 'number' ? 0 : undefined,
          maxLength: maxLength,
        }}
        error={Boolean(errors[field]) && touched[field]}
        helperText={
          Boolean(errors[field]) && touched[field]
            ? errors[field]
            : inputProps?.helper
        }
        disabled={loading || inputProps?.readOnly}
      >
        {options &&
          Object.keys(options).map((optionKey) => (
            <MenuItem key={optionKey} value={optionKey}>
              {options[optionKey]}
            </MenuItem>
          ))}
      </TextField>
    </>
  )
}

Field.propTypes = {
  gutterBottom: PropTypes.bool,
}

export default Field
