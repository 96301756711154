import React from 'react'

import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { generateModalContent } from 'utils/smartyStreets'

import Modal from './Modal'

function AddressCorrectionModal({
  updatedValues,
  addressValues,
  addressKeys,
  onContinue,
  ...props
}) {
  const cardSx = {
    border: '1px solid',
    p: 2,
    mb: 2,
    height: '100%',
    width: '100%',
  }

  const buttonProps = {
    variant: 'contained',
    sx: {
      width: '100%',
    },
  }

  return (
    <Modal
      {...props}
      title="Choose Correct Address"
      width="732px"
      height="600px"
    >
      <Box sx={{ height: '100%' }}>
        <Alert severity="info" sx={{ mb: 2 }}>
          We've found a similar address to the one provided. Please select
          address you wish to use.
        </Alert>
        <Grid container columns={10}>
          <Grid item xs={5} sx={{ pr: 1 }}>
            <Typography fontWeight="bold">Original Address:</Typography>
            <Card sx={cardSx}>
              {generateModalContent(
                addressValues?.[0],
                false,
                true,
                false,
                addressKeys
              )}
            </Card>
            <Button
              {...buttonProps}
              onClick={() => {
                onContinue(addressValues)
              }}
            >
              Use Original Address
            </Button>
          </Grid>
          <Grid item xs={5} sx={{ pl: 1 }}>
            <Typography fontWeight="bold">Suggested Address:</Typography>
            <Card sx={cardSx}>
              {generateModalContent(
                updatedValues?.[0],
                false,
                true,
                false,
                addressKeys
              )}
            </Card>
            <Button
              {...buttonProps}
              onClick={() => {
                onContinue(updatedValues)
              }}
            >
              Use Suggested Address
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}

export default AddressCorrectionModal
